var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{staticClass:"button-theme",class:{
      'styles-moon': !_vm.isDarkModeActive,
      'styles-sun': _vm.isDarkModeActive,
    },on:{"click":_vm.toggleTheme}},[_c('i',{staticClass:"fa-solid",class:{
        'fa-moon': !_vm.isDarkModeActive,
        'fa-sun': _vm.isDarkModeActive,
      }})])])
}
var staticRenderFns = []

export { render, staticRenderFns }