<script>
export default {
  mounted() {
    this.darkMode();
  },
  methods: {
    darkMode() {
      const isDarkModeActive = JSON.parse(localStorage.getItem("isdark")) == null ? false : JSON.parse(localStorage.getItem("isdark"));
      this.isDarkModeActive = isDarkModeActive;
      console.log("localstorage dark toogle ", this.isDarkModeActive);
      // console.log("localstorage dark button ", isDarkModeActive);
      const body = document.querySelector("body");
      if (isDarkModeActive) {
        body.classList.add("dark");
      } else {
        body.classList.remove("dark");
        this.$forceUpdate();
      }
    },
    toggleTheme() {
      // const isDark = document.body.classList.toggle("dark");
      this.isDarkModeActive = !this.isDarkModeActive;
      console.log("localstorage dark toogle ", this.isDarkModeActive);
      const body = document.querySelector("body");
      localStorage.setItem("isdark", JSON.stringify(this.isDarkModeActive));
      if (this.isDarkModeActive) {
        body.classList.add("dark");
      } else {
        body.classList.remove("dark");
      }
      this.$forceUpdate();
    },
  },
  data() {
    return {
      isDarkModeActive: false,
    };
  },
};
</script>

<template>
  <div>
    <button
      @click="toggleTheme"
      class="button-theme"
      :class="{
        'styles-moon': !isDarkModeActive,
        'styles-sun': isDarkModeActive,
      }"
    >
      <i
        class="fa-solid"
        :class="{
          'fa-moon': !isDarkModeActive,
          'fa-sun': isDarkModeActive,
        }"
      ></i>
    </button>
  </div>
</template>

<style scoped>
.button-theme {
  border-radius: 50%;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 22px;
  cursor: pointer;
  transition: 0.3s;
  border: none;
}

.styles-moon {
  background-color: #0c1a30;
  color: #fff;
}

.styles-sun {
  background-color: #fe721c;
  color: white;
}
</style>
